<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

import { authUserMethods } from  "../../../state/helpers/authuser"
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      is_show_password: false,
    };
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authUserMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loginUser({email:this.email, password:this.password});
      }
    },
    handleViewPassword() {
      this.is_show_password = !this.is_show_password
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center ">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="">
              <div class="text-success text-center p-4">
                <h3 class="text-success font-size-40">Safe and Sound Real Estate Inc</h3>
                <p class="text-dark">Login to your account</p>
                
                  <!-- <img
                    src="@/assets/images/logo-sm.png"
                    height="24"
                    alt="logo"
                  /> -->
                  <!-- <h3><a href="/" class="logo logo-admin text-orange">SRA </a></h3> -->
               
              </div>
            </div>
            <div class="card-body p4" style="margin-top:-60px !important;">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                  >{{ authError }}</b-alert
                >
                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <b-form
                  @submit.prevent="tryToLogIn"
                  class="form-horizontal mt-4"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Email"
                    label-for="input-1"
                    class="mb-3"
                    label-class="form-label"
                  >
                    <b-form-input
                      id="input-1"
                      :class="{ 'is-invalid': submitted && $v.email.$error }"
                      v-model="email"
                      type="email"
                      placeholder="Enter email"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.email.required">Email is required.</span>
                      <span v-if="!$v.email.email"
                        >Please enter valid email.</span
                      >
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Password"
                    label-for="input-2"
                    class="mb-3 password-view"
                    label-class="form-label"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="password"
                      :type="is_show_password ?  'text' : 'password'"
                      placeholder="Enter password"
                      :class="{ 'is-invalid': submitted && $v.password.$error }"
                    ></b-form-input>
                    <div class="password-visibile-box" @click="handleViewPassword">
                        <span class='mdi' :class="is_show_password ? 'mdi-eye-off' : 'mdi-eye'"></span>
                      </div>
                    <div
                      v-if="submitted && !$v.password.required"
                      class="invalid-feedback"
                    >
                      Password is required.
                    </div>
                  </b-form-group>
<!-- 
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          class="form-check-label"
                          for="customControlInline"
                          >Remember me</label
                        >
                      </div>
                    </div>
                  
                  </div> -->

                  <div class="row">
                    <div class="col-md-12 mt-2">
                      <div style="float: right !important;">
                        <b-button type="submit" variant="outline-orange" class="btn-block"
                        >Login</b-button
                      >

                      </div>
                    
                    </div>
                  </div>


                  <div class="mt-2 mb-0 row">
                    <div class="col-12 mt-4 ">
                      <router-link to="/forgot-password" class="text-orange">
                        <i class="mdi mdi-lock"></i> Reset password
                      </router-link>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <!-- <p>
              Don't have an account ?
              <router-link to="/register" class="fw-medium text-orange"
                >Signup now</router-link
              >
            </p> -->
            <p class="mb-0">
              ©{{ new Date().getFullYear() }} Safe and Sound Real Estate Inc

            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
